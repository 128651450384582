var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container shadow-sm"},[[_c('div',{staticClass:"row d-flex info-icon info-important border rounded-lg p-3"},[_c('div',{staticClass:"d-flex align-items-center mb-0"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("Warning")]),_c('span',[_vm._v("Fecha de actualización de información de tu tarjeta: "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.fechaActualizacionInfo))])])])]),_c('div',{staticClass:"row items"},[_c('div',{staticClass:"col-sm-6 d-flex flex-column item"},[_vm._m(0),_c('div',{staticClass:"value important"},[_vm._v(_vm._s(_vm.valorPagoMinimo))])]),_c('div',{staticClass:"col-sm-6 d-flex flex-column item"},[_vm._m(1),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.cupoDisponible))])]),_c('div',{staticClass:"col-sm-6 d-flex flex-column item"},[_vm._m(2),_c('div',{staticClass:"value important"},[_vm._v(_vm._s(_vm.valorPagoTotal))]),_c('v-icon',{attrs:{"icon":"md:home"}})],1),_c('div',{staticClass:"col-sm-6 d-flex flex-column item"},[_vm._m(3),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.cupoTotal))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"item-icon justify-content-between d-sm-flex d-md-block",class:{'saldo-en-mora': _vm.conRestricciones}},[_vm._m(4),_c('div',{staticClass:"mx-auto",class:{'d-flex justify-content-center': _vm.conRestricciones}},[_c('span',{staticClass:"date date-card important"},[_vm._v(_vm._s(_vm.conRestricciones ? "⚠️ Inmediato" :_vm.fechaLimitePago))])]),_c('div')])]),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"item-icon justify-content-between d-sm-flex d-md-block"},[_vm._m(5),_c('div',{},[_c('span',{staticClass:"date date-card"},[_vm._v(_vm._s(_vm.fechaCorte))])]),_c('div')])])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex item-icon"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("price_check")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Valor Pago Mínimo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex item-icon"},[_c('i',{staticClass:"fas fa-hand-holding-usd"}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cupo Disponible:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex item-icon"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("Paid")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Valor Pago Total:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex item-icon"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("Payments")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cupo Total:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("calendar_clock")]),_c('span',{staticClass:"descriptions"},[_vm._v(" Fecha límite de pago: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"m-menu__link-icon material-symbols-outlined"},[_vm._v("calendar_clock")]),_c('span',{staticClass:"descriptions"},[_vm._v(" Fecha de corte: ")])])
}]

export { render, staticRenderFns }